<template>
  <div class="my-4 flex flex-col items-center justify-center text-center">
    <img
      src="~/assets/img/component-error.svg"
      alt="Error"
      class="mx-auto max-h-96"
      @click="clearComponentError"
    >

    <h1 class="md:text-md mt-4 text-lg font-medium text-gray-500">
      {{ error.title ? $t(error.title) : $t('reports.errors.generic.header.default') }}
    </h1>
    <p
      v-if="error.statusMessage || errorCount > 1"
      class="mb-4 mt-1.5 text-sm text-gray-500 md:text-xs"
    >
      {{ error.statusMessage ? $t(error.statusMessage) : $t('reports.errors.generic.message.refresh') }}
    </p>
    <HubButton
      class="my-2"
      :label="$t(`errors.generic.tryAgain`)"
      @click="clearComponentError"
    />
  </div>
</template>

<script setup lang="ts">
import type { CustomErrorType } from '~/types'

const emit = defineEmits<{
  (e: 'update:error', arg1: string | undefined): void
}>()

const props = withDefaults(defineProps<{
  error: CustomErrorType
  clearError?: () => void
  errorCount?: number
}>(),
{
  errorCount: 0
}
)

function clearComponentError() {
  emit('update:error', undefined)
  if (props.clearError) {
    props.clearError()
  }
}

defineExpose({
  clearComponentError
})
</script>
