import type { HubSectionConfig } from '~/types/configuration'

export const useSectionStore = defineStore('section', () => {
  const sectionList: Ref<Array<HubSectionConfig>> = ref([])
  const getSectionList: ComputedRef<Array<HubSectionConfig>> = computed(() => sectionList.value)

  async function fetchSections() {
    if (sectionList.value && sectionList.value.length) {
      return sectionList.value
    }

    try {
      sectionList.value = (await $hubFetch('api/v4/lookups/dashboards/sections')) as Array<HubSectionConfig>
    } catch {
      throw new Error('Sections Not Loaded')
    }
  }

  return {
    sectionList,
    getSectionList,
    fetchSections
  }
})
